import React from 'react';

const CVData = {

    /* Personal */
    'name': 'Boldogh Gergő',
    'title': 'Tech Lead, Full Stack Software Engineer',
    'address': <><span>Cím: </span>6000 Kecskemét</>,
    'phone': <><span>Telefon: </span>+36-30/709-5330</>,
    'email': <><span>E-mail: </span>info@gexa.hu</>,
    'nationality': <><span>Állampolgárság: </span>MAGYAR</>,
    'birth': <><span>Születési hely, idő: </span>Gyula, 1987. szeptember 19.</>,

    /* Content */
    'intro': `16 év szakmai tapasztalattal rendelkező, megbízható, a határidőkre és munkájára igényes fejlesztőnek tartom magam, aki minden szituációban megpróbálja a legjobbat nyújtani. 
                Megbízómmal szemben lojális, munkatársaimmal pedig mindig emberséges és segítőkész vagyok. 
                Ezen éveim alatt több, mint száz projekt fejlesztésében vettem részt sikeresen, melyek nagy többségét egyedül, vagy kis csapatban (2-3 fő) készítettem. 
                Az elmúlt években belekóstoltam a nagyvállalati és banki környezetekbe is, ahol rengeteg új tapasztalatot szereztem és nagyon sok új technológiát sajátítottam el.
                Ennek köszönhetően mára Tech Lead szerepet töltök be jelenlegi (és előző) munkahelyemen, ahol egy 10+ fős csapatban dolgozom.`,
    'mygoal': `Szeretném az eddig megszerzett tudásomat, nagyobb, további globálisan elismert projekt(ek)ben
                kamatoztatni egy csapat részeként, ahol a kezdőket segíthetem, viszont még rengeteg dolgot tanulhatok.`,

    'profession': {
        title: 'Szakmai tapasztalatok',
        content: [
            {
                'date': '2024. február - jelenleg',
                'title': 'MBH Bank Nyrt.',
                'description': 'Squad Tech Lead, Senior Software Engineer: A Bank új Core rendszerének megújításában veszek részt, ahol egy 7 fejlesztőből álló csapatot vezetek, mint Tech-Lead és Enabler.'
            },
            {
                'date': '2022. október 03 - 2024. február',
                'title': 'MBH Bank Nyrt.',
                'description': 'Software Engineer (Full Stack): A bank új funkcióinak (fedezetlen hitelezési terület) fejlesztésében vettem részt Microservice és Microfrontend architektúrában.'
            },
            {
                'date': '2021. szeptember 13 - 2022. szeptember 13.',
                'title': `Last<span style="color: red">Pass</span> (LogMeIn / GoTo)`,
                'description': 'Software Engineer (Full Stack): Új funkciók fejlesztésén, illetve a meglévő funkciók refaktorálásán dolgoztam egy kis, 5-8 fős csapatban.'
            },
            {
                'date': '2010.01.12 - jelenleg',
                'title': 'Ügyvezető, Full Stack webfejlesztő',
                'description': 'Szabadúszó Full Stack webfejlesztőként komplex rendszerek tervezését és teljes kivitelezését csináltam és csinálom a mai napig.'
            },
            {
                'date': '2008.05.02 - 2010.01.10.',
                'title': 'Egyéni vállalkozó, Full Stack webfejlesztő',
                'description': 'Megbízásos jogviszonyban, alvállalkozóként egyszerű weboldalak / hírportálok fejlesztése'
            }
        ]
    },
    'schools': {
        title: <>Tanulmányok, <span>végzettségek</span></>,
        content: [
            {
                'date': '2007.06.18.',
                'title': 'Angol, C típusú felsőfokú nyelvvizsga',
                'description': 'Ady Endre - Bay Zoltán Középiskola és Kollégium, Sarkad'
            },
            {
                'date': '2002.09.01 - 2007.06.18.',
                'title': 'Gimnáziumi Értettségi',
                'description': 'Ady Endre - Bay Zoltán Középiskola és Kollégium, Sarkad'
            },
            {
                'date': '2001.05.10.',
                'title': <>ECDL vizsga <small>(European Computer Driving License)</small></>,
                'description': '1. Számú Általános Iskola, Sarkad'
            },
            {
                'date': '1994.09.01 - 2002.06.15.',
                'title': '8 általános, emelt matematika és informatika csoport',
                'description': '1. Számú Általános Iskola, Sarkad'
            },
        ]
    },
    'technologies_title': 'Szakmai kompetenciák',
    'technologies': {
        'tags': {
            'title': 'Programozási nyelvek / technológiák',
            'content': [
                {
                    'title': 'PHP',
                    'description': '15+ év'
                },
                {
                    'title': 'JavaScript',
                    'description': '14+ év'
                },
                {
                    'title': 'TypeScript',
                    'description': '4+ év'
                },
                {
                    'title': 'HTML5',
                    'description': '10+ év'
                },
                {
                    'title': 'CSS/CSS3',
                    'description': '13+/7+ év'
                },
                {
                    'title': <>CMS <span>fejlesztés</span></>,
                    'description': '10+ év'
                },
                {
                    'title': 'MySQL',
                    'description': '10+ év'
                },
                {
                    'title': 'Postgres SQL',
                    'description': '2+ év'
                },
                {
                    'title': 'Microservice',
                    'description': '1.5+ év'
                },
                {
                    'title': 'NodeJS',
                    'description': '5+ év'
                },
                {
                    'title': 'NestJS',
                    'description': '1.5+ év'
                },
                {
                    'title': 'React',
                    'description': '5+ év'
                },
                {
                    'title': 'Microfrontend',
                    'description': '1.5+ év'
                },
                {
                    'title': 'RestAPI',
                    'description': '10+ év'
                },
                {
                    'title': 'GraphQL',
                    'description': '1.5+ év'
                },
                {
                    'title': 'MongoDB',
                    'description': '2+ év'
                },
                {
                    'title': 'SASS/SCSS',
                    'description': '4+ év'
                },
                {
                    'title': 'UI libraries',
                    'description': '5+ év'
                },
                {
                    'title': 'Next.js',
                    'description': '2+ év'
                },
                {
                    'title': 'CI/CD',
                    'description': '4 év'
                },
                {
                    'title': 'Github',
                    'description': '6+ év'
                },
                {
                    'title': 'GitLab',
                    'description': '1.5+ év'
                },
                {
                    'title': 'Camunda',
                    'description': '2+ hónap'
                },
            ]
        },
        'projects': {
            'title': 'Kiemelt projekjeim',
            'content': [
                {
                    'title': <><em>2023-2024.</em> MyFactory.co, BulkLive.com</>,
                    'description': '(NextJS alkalmazások "API proxy" megoldással, keresőoptimazálással, Laravel backend (RestAPI), Elasticsearch, Google Compute Engine)'
                },
                {
                    'title': <><em>2022-2023.</em> Vállalkozók Országos Szövetsége - Online piactér</>,
                    'description': '(frontend / sitebuild + backend fejlesztése, saját, PHP 7+ alapú MVC keretrendszerben, régi adatállomány konvertálása a saját framework-be)'
                },
                {
                    'title': <><em>2021.</em> Vállalkozók Országos Szövetsége - Tagnyilvántartó rendszer</>,
                    'description': '(frontend / sitebuild + backend fejlesztése, saját, PHP 7+ alapú MVC keretrendszerben, régi adatállomány konvertálása a saját framework-be)'
                },
                {
                    'title': <><em>2021.</em> Sonepar Magyarország Kft. - 14. Sonepar Szakmai Napok - Virtuális kiállítás</>,
                    'description': '(Online virtuális kiállítási rendszer tervezése, frontend + backend fejlesztése, saját, PHP 7+ alapú MVC keretrendszerben, background workerek készítése websocket alapon)'
                },
                {
                    'title': <><em>2020.</em> Pénzügyi Szemle - Weboldal fejlesztése</>,
                    'description': '(tervezés, frontend + backend fejlesztése saját, PHP 7+ alapú MVC keretrendszerben)'
                },
                {
                    'title': <><em>2019 - 2020.</em> Békés Megyéért Vállalkozásfejlesztési Alapítvány - Online Vállalkozás-építő játék fejlesztése, EU pályázat</>,
                    'description': '(weboldal és játék grafika tervezése, frontend + backend fejlesztése saját, PHP 7+ alapú MVC keretrendszerben, WebSocket alapú background workerek készítése)'
                },
                {
                    'title': <><em>2016 - 2020.</em> UtopiaLive <small>(Már megszűnt)</small> - 18+ Közösségi oldal + Streaming rendszer</>,
                    'description': '(Weboldal tervezése, frontend + backend fejlesztése saját, PHP 7+ alapú MVC keretrendszerben, WebSocket + NodeJS + MongoDB alapú chat rendszer, Google Compute Engine + Docker alapú Streaming engine /NGINX RTMP/, illetve VM Control Panel fejlesztése React + PHP (Google Cloud API) alapon a GCE instance-ok kezeléséhez.)'
                },
                {
                    'title': <><em>2018.</em> Hungarospa, Hajdúszoboszló - Weboldal és Online jegyértékesítési rendszer</>,
                    'description': '(tervezés, frontend + backend fejlesztése saját, PHP 7+ alapú MVC keretrendszerben)'
                },
                {
                    'title': <><em>2014-2018.</em> ÉtelVitel.hu - Online ételrendelési "webshop"</>,
                    'description': '(tervezés, frontend + backend fejlesztése saját, PHP 5 alapú MVC keretrendszerben)'
                },
            ]
        }
    },
    'personal': {
        'title': 'Személyes kompetenciák',
        'content': [
            {
                'date': 'Anyanyelv',
                'title': 'MAGYAR'
            },
            {
                'date': 'Egyéb nyelvek',
                'title': <>Angol <span className="thin">| "C" típusú, felsőfokú nyelvvizsga</span><br />
                            Német <span className="thin">| Alapszint</span></>
            },
            {
                'date': 'Egyéb',
                'title': 'B Kategóriás vezetői engedély'
            }
        ]
    }
}

export default CVData;
